import { Paper, useTheme, alpha } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useParticipant } from "../../context/ParticipantContext";
import moment from "moment";
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useHistory } from "react-router-dom";

Chart.register(
  CategoryScale, LinearScale, BarElement, LineElement,
  Title, Tooltip, Legend,
  zoomPlugin
);

const StudyDataChartSleep: React.FC = () => {
  const { studyData, selectedStudy } = useAuth();
  const { setSelectedParticipant } = useParticipant();
  const theme = useTheme();
  const history = useHistory();
  const [chartData, setChartData] = useState<any>(null);
  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (studyData && studyData.participants) {
      const numSleeps = studyData.participants.map((participant: any) => participant.withings_sleep_stats.num_sleeps);
      const dataDuration = studyData.participants.map((participant: any) => {
        const firstSleep = moment(participant.withings_sleep_stats.first_sleep);
        const lastSleep = moment(participant.withings_sleep_stats.last_sleep);
        const extraDays = moment.duration(lastSleep.diff(firstSleep)).asDays() - participant.withings_sleep_stats.num_sleeps;
        return extraDays > 0 ? extraDays : 0;
      });

      const data = {
        labels: studyData.participants.map((participant: any, index: number) => `${participant.lab_id}`),
        datasets: [
          {
            label: 'Number of Sleeps',
            data: numSleeps,
            backgroundColor: alpha(theme.palette.primary.main, 1),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 1),
            barThickness: 12,
            borderRadius: 4,
          },
          {
            label: 'Data Duration (Days)',
            data: dataDuration,
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 0.75),
            hoverBorderColor: alpha(theme.palette.primary.main, 1),
            barThickness: 12,
            borderRadius: 4
          },
        ],
      };

      setChartData(data);
    }
  }, [studyData, theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.main]);
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Distribution of Participant Sleeps and Data Duration',
      }
    },
    scales : {
      y: {
        stacked: true,
        color: theme.palette.text.primary,
        tickColor: theme.palette.text.primary
      },
      x: {
        stacked: true,
        color: theme.palette.text.primary,
        tickColor: theme.palette.text.primary
      }
    },
    interaction: {
        mode: 'index' as const,
        intersect: false,
        axis: 'x' as const
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const participantId = studyData.participants[elementIndex].lab_id;
        const url = `/dashboard?study=${selectedStudy}&participant=${participantId}`;
        const baseUrl = history.location.pathname.split('/dashboard')[0];
        setSelectedParticipant(participantId)
        history.push(baseUrl + url);
      }
    },
  };

  return (
    <Paper sx = {{ 
      padding: '1%', width: '90%', height: '100%', marginBottom: '3%',
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      {chartData ? <Bar ref={chartRef} data={chartData} options={options} /> : <p>Loading...</p>}
    </Paper>
  );
};

const StudyDataChartBPTrend: React.FC = () => {
  const { studyData } = useAuth();
  const theme = useTheme();
  const [chartData, setChartData] = useState<any>(null);
  const chartRef = useRef<any>(null);

  useEffect(() => {
    if (studyData && studyData.participants) {
      let dataPWeek = studyData.participants.map((participant: any) => participant.withings_meas_stats.bp_per_week);
      console.log(dataPWeek);

      // Flatten the arrays and group by week
      const groupedByWeek = dataPWeek.flat().reduce((acc: any, curr: any) => {
        if (!acc[curr.week]) {
          acc[curr.week] = [];
        }
        acc[curr.week].push(curr.num_bp);
        return acc;
      }, {});

      // Calculate averages and standard deviations
      const dataPWeekAvg = Object.values(groupedByWeek).map((weekData: any) => 
        weekData.reduce((a: number, b: number) => a + b, 0) / weekData.length
      );

      const dataPWeekSD = Object.values(groupedByWeek).map((weekData: any, index: number) => 
        Math.sqrt(weekData.reduce((a: number, b: number) => a + Math.pow(b - dataPWeekAvg[index], 2), 0) / weekData.length)
      );

      const labels = Object.keys(groupedByWeek).map((week: any) => `Week ${week}`);

      const data = {
        labels: labels,
        datasets: [
          {
            label: 'Weekly Average BP',
            data: dataPWeekAvg,
            type: 'line',
            borderColor: alpha(theme.palette.primary.main, 1),
            borderWidth: 2,
            fill: false,
            errorBars: dataPWeekSD,  // Custom property for error bars
            yAxisID: 'y1',
            cubicInterpolationMode: 'monotone',
            pointStyle: false,
          },
          // Add a line for each participant
          ...dataPWeek.map((participantData: any, index: number) => {
            // Create a map for easy lookup
            const weekMap = participantData.reduce((acc: any, curr: any) => {
              acc[curr.week] = curr.num_bp;
              return acc;
            }, {});
  
            // Fill in missing weeks with null
            const data = labels.map((label: string) => {
              const week = parseInt(label.replace('Week ', ''));
              return weekMap[week] || null;
            });
  
            return {
              label: `Participant ${index + 1}`,
              data,
              type: 'line',
              borderColor: `rgba(0, 0, 0, 0.1)`,  // Reduced alpha
              hoverBorderColor: `rgba(0, 0, 0, 0.1)`,  // Reduced alpha
              pointBackgroundColor: `rgba(0, 0, 0, 0.05)`,  // Reduced alpha
              hoverBackgroundColor: `rgba(0, 0, 0, 0.2)`,  // Reduced alpha
              borderWidth: 1,
              yAxisID: 'y2',
              cubicInterpolationMode: 'monotone',
              pointRadius: 5,
            };
          }),
        ],
      };

      setChartData(data);
    }
  }, [studyData, theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main, theme.palette.success.dark]);
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Distribution of Participant Sleeps and Data Duration',
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const datasetIndex = context.datasetIndex;
            if (datasetIndex === 0) { // Only for the first dataset (Weekly Average BP)
              const value = context.parsed.y;
              const label_value = `Weekly Average BP: ${value.toFixed(1)} ±${context.dataset.errorBars[context.dataIndex].toFixed(1)}\n`
              return label_value;
            } else {
              return null;
            }
          },
          footer: function(tooltipItems: any) {
            // The number of datasets is the length of tooltipItems minus 1 (for the first dataset)
            const datasetCount = tooltipItems.length - 1;
            return `Participants: ${datasetCount}`;
          }
        },
        yAlign: 'top' as const,
        position: 'nearest' as const,
      },
      zoom : {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'x' as const,
        },
        pan: {
          enabled: true,
          mode: 'x' as const
        },
      }
    },
    scales : {
      y1: {
        type: 'linear' as const,
        position: 'left' as const,
        beginAtZero: true,
      },
      y2: {
        type: 'linear' as const,
        position: 'right' as const,
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    interaction: {
        mode: 'index' as const,
        intersect: false,
        axis: 'x' as const
    }
  };

  return (
    <Paper sx = {{ 
      padding: '1%', width: '90%', height: '100%', marginBottom: '3%',
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      {chartData ? <Line ref={chartRef} data={chartData} options={options} /> : <p>Loading...</p>}
    </Paper>
  );
};

const StudyDataChartBP: React.FC = () => {
  const { studyData, selectedStudy } = useAuth();
  const { setSelectedParticipant } = useParticipant();
  const theme = useTheme();
  const history = useHistory();
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if (studyData && studyData.participants) {
      const numBPs = studyData.participants.map((participant: any) => participant.withings_meas_stats.num_bps);
      const dataDuration = studyData.participants.map((participant: any) => {
        const firstSleep = moment(participant.withings_sleep_stats.first_sleep);
        const lastSleep = moment(participant.withings_sleep_stats.last_sleep);
        const extraDays = moment.duration(lastSleep.diff(firstSleep)).asDays() - participant.withings_sleep_stats.num_sleeps;
        return extraDays > 0 ? extraDays : 0;
      });

      const dataPWeek = studyData.participants.map((participant: any) => participant.withings_meas_stats.bp_per_week);
      console.log(dataPWeek);

      const dataPWeekAvg = dataPWeek.map((weekData: any) => 
        weekData.reduce((a: number, b: number) => a + b, 0) / weekData.length
      );

      const dataPWeekSD = dataPWeek.map((weekData: any, index: number) => 
        Math.sqrt(weekData.reduce((a: number, b: number) => a + Math.pow(b - dataPWeekAvg[index], 2), 0) / weekData.length)
      );

      const data = {
        labels: studyData.participants.map((participant: any, index: number) => `${participant.lab_id}`),
        datasets: [
          {
            label: 'Number of BP Measurements',
            data: numBPs,
            backgroundColor: alpha(theme.palette.primary.main, 1),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 1),
            barThickness: 12,
            borderRadius: 4,
          },
          {
            label: 'Data Duration (Days)',
            data: dataDuration,
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 0.75),
            hoverBorderColor: alpha(theme.palette.primary.main, 1),
            barThickness: 12,
            borderRadius: 4
          }
        ],
      };

      setChartData(data);
    }
  }, [studyData, theme.palette.primary.main, theme.palette.secondary.main, theme.palette.success.main, theme.palette.success.dark, theme.palette.primary.light]);
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Distribution of Participant Sleeps and Data Duration',
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            label += Math.round(context.parsed.y * 100) / 100;
            if (context.dataset.type === 'line') {
              const errorBar = context.dataset.errorBars[context.dataIndex];
              label += ` (±${Math.round(errorBar * 100) / 100})`;
            }
            return label;
          },
        },
      },
    },
    scales : {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      }
    },
    interaction: {
        mode: 'index' as const,
        intersect: false,
        axis: 'x' as const
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const participantId = studyData.participants[elementIndex].lab_id;
        const url = `/dashboard?study=${selectedStudy}&participant=${participantId}`;
        const baseUrl = history.location.pathname.split('/dashboard')[0];
        setSelectedParticipant(participantId)
        history.push(baseUrl + url);
      }
    },
  };

  return (
    <Paper sx = {{ 
      padding: '1%', width: '90%', height: '100%', marginBottom: '3%',
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      {chartData ? <Bar data={chartData} options={options} /> : <p>Loading...</p>}
    </Paper>
  );
};

const StudyDataChartWeight: React.FC = () => {
  const { studyData, selectedStudy } = useAuth();
  const { setSelectedParticipant } = useParticipant();
  const theme = useTheme();
  const history = useHistory();
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if (studyData && studyData.participants) {
      const numWeights = studyData.participants.map((participant: any) => participant.withings_meas_stats.num_weights);
      const dataDuration = studyData.participants.map((participant: any) => {
        const firstSleep = moment(participant.withings_sleep_stats.first_sleep);
        const lastSleep = moment(participant.withings_sleep_stats.last_sleep);
        const extraDays = moment.duration(lastSleep.diff(firstSleep)).asDays() - participant.withings_sleep_stats.num_sleeps;
        return extraDays > 0 ? extraDays : 0;
      });

      const data = {
        labels: studyData.participants.map((participant: any, index: number) => `${participant.lab_id}`),
        datasets: [
          {
            label: 'Number of Weight Measurements',
            data: numWeights,
            backgroundColor: alpha(theme.palette.primary.main, 1),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 1),
            barThickness: 12,
            borderRadius: 4,
          },
          {
            label: 'Data Duration (Days)',
            data: dataDuration,
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            hoverBackgroundColor: alpha(theme.palette.primary.light, 0.75),
            hoverBorderColor: alpha(theme.palette.primary.main, 1),
            barThickness: 12,
            borderRadius: 4
          },
        ],
      };

      setChartData(data);
    }
  }, [studyData, theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.main]);
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Distribution of Participant Sleeps and Data Duration',
      },
    },
    scales : {
      y: {
        stacked: true,
      },
      x: {
        stacked: true,
      }
    },
    interaction: {
        mode: 'index' as const,
        intersect: false,
        axis: 'x' as const
    },
    onClick: (event: any, elements: any) => {
      if (elements.length > 0) {
        const elementIndex = elements[0].index;
        const participantId = studyData.participants[elementIndex].lab_id;
        const url = `/dashboard?study=${selectedStudy}&participant=${participantId}`;
        const baseUrl = history.location.pathname.split('/dashboard')[0];
        setSelectedParticipant(participantId)
        history.push(baseUrl + url);
      }
    },
  };

  return (
    <Paper sx = {{ 
      padding: '1%', width: '90%', height: '100%', marginBottom: '3%',
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    }}>
      {chartData ? 
      <Bar 
        data={chartData}
        options={options}
        /> : <p>Loading...</p>}
    </Paper>
  );
};
  
export {StudyDataChartSleep, StudyDataChartBP, StudyDataChartBPTrend, StudyDataChartWeight};