import * as React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { generateAndDownloadPDF } from '../../functions/generatePDF';
import { useParticipant } from '../../context/ParticipantContext';
import { useDash } from '../../context/DashContext';
import { useAuth } from '../../context/AuthContext';

import dayjs, { Dayjs } from 'dayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Box, Checkbox, Divider, ListItemText, MenuItem, Select, Switch, useTheme } from '@mui/material';

import { DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/en-au';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function FormPdf(data: any, refs: any) {
  const [open, setOpen] = React.useState(false);

  const { chartRefs, columnsList } = useDash();
  const { selectedColumns, setSelectedColumns, showAnnotations, setShowAnnotations } = useAuth();
  const { summaryData, earliestDate, startDate, setStartDate, latestDate, endDate, setEndDate } = useParticipant();

  const theme = useTheme();

  const [start, setStart] = React.useState<Dayjs | null>(null);
  const [end, setEnd] = React.useState<Dayjs | null>(null);
  const [allTime, setAllTime] = React.useState<boolean>(true);

  const origStartDate = earliestDate;
  const origEndDate = latestDate;

  const handleClickOpen = () => {
    setOpen(true);
    console.log(startDate, endDate);
    console.log(earliestDate, latestDate);
    let startTemp = null;
    let endTemp = null;
    startTemp = !startDate ? dayjs(earliestDate) : dayjs(startDate);
    endTemp = !endDate ? dayjs(latestDate) : dayjs(endDate);

    setStart(startTemp);
    setEnd(dayjs(endTemp));
  };

  const handleClose = () => {
    console.log("HERE")
    console.log(origStartDate, origEndDate);
    setStartDate(origStartDate);
    setEndDate(origEndDate);
    setOpen(false);
  };

  const handleAllDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllTime(event.target.checked);
    if (event.target.checked) {
      setStartDate(earliestDate);
      setEndDate(latestDate);
    }
  };

  const handleAnnotationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAnnotations(event.target.checked);
  }

  const [cleared, setCleared] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);
  
  return (
    <React.Fragment>
      <Tooltip title="Generate PDF Report">
        <IconButton onClick={handleClickOpen}
          sx={{
            borderRadius: '1rem',
            padding: '0.5rem',
            margin: '0.2rem',
          }}
        >
          <PictureAsPdfIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const startDate = formJson.startDate;
            console.log(startDate);
            generateAndDownloadPDF(summaryData, chartRefs);
            
            setStartDate(origStartDate);
            setEndDate(origEndDate);
            handleClose();
          },
        }}
      >
        <DialogTitle>PDF Generation for {summaryData.participantID}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '1rem',
            }}
          >
          <DialogContentText>
              Show annotations (means, events)
            </DialogContentText>
            <Switch checked={showAnnotations} onChange={handleAnnotationsChange}/>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: '1rem',
            }}
          >
            <DialogContentText>
              All data (or select date range)
            </DialogContentText>
            <Switch checked={allTime} onChange={handleAllDataChange}/>
          </Box>
          {!allTime ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-au'>
                <DatePicker
                  label="Start Date"
                  sx={{ margin: '1rem' }}
                  defaultValue={start} 
                  onChange={(newValue) => {
                    let v = newValue as Dayjs;
                    let val = null;
                    if (v) {
                      val = v.toDate();
                    }
                    setStartDate(val as Date || null);
                  }}
                  slotProps={{
                    field: { clearable: true, onClear: () => setCleared(true) },
                  }}
                />
                <DatePicker
                  label="End Date"
                  sx={{ margin: '1rem' }}
                  defaultValue={end} 
                  onChange={(newValue) => {
                    let v = newValue as Dayjs;
                    let val = null;
                    if (v) {
                      val = v.toDate();
                    }                 
                    setEndDate(val as Date || null);
                  }}
                  slotProps={{
                    field: { clearable: true, onClear: () => setCleared(true) },
                  }}
                />
              </LocalizationProvider>
            </Box>
          ) : null }
        <Divider 
          sx={{
            margin: '1rem',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DialogContentText>
            Selected Charts
          </DialogContentText>
          <Select
            sx = {{
              // backgroundColor: alpha(theme.palette.text.secondary, 0.6),
              // borderColor: alpha(theme.palette.text.secondary, 0.1),
              // color: theme.palette.primary.contrastText,
              // boxShadow: '2px 5px 5px 0px ' + alpha("000", 0.3),
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              color: theme.palette.text.secondary,
              boxShadow: 'none',
              width: '100%',
            }}
            labelId="column-select-label"
            id="column-multiple-checkbox"
            multiple
            value={selectedColumns}
            onChange={(event) => {
                const value = event.target.value;
                // On autofill we get a stringified value.
                setSelectedColumns(typeof value === 'string' ? value.split(',') : value);
            }}
            // renderValue={(selected) => selected.map(column => column.replace('w_', '').replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())).join(', ')}
            renderValue={(selected) => selected.length === 0 ? null : `${selected.length} selected`}
            displayEmpty
            MenuProps={MenuProps}
          >
              {columnsList.map((column) => (
                  <MenuItem key={column} value={column}>
                      <Checkbox checked={selectedColumns.indexOf(column) > -1} />
                      <ListItemText primary={column.replace('w_', '').replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())} />
                  </MenuItem>
              ))}
          </Select>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Generate PDF</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}