import React, { useState } from 'react';
import { 
  Fab, Menu, MenuItem, IconButton, FormControlLabel, Box,
  FormControl, Switch, FormLabel, RadioGroup, Radio, SpeedDialIcon,
  SpeedDialAction, Button, TextField, Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // For dark mode
import Brightness7Icon from '@mui/icons-material/Brightness7'; // For light mode
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PrintIcon from '@mui/icons-material/Print';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import SpeedDial, { SpeedDialProps } from '@mui/material/SpeedDial';

import { useHistory } from 'react-router-dom';
import { useTheme } from '../context/themeContext';
import { useAuth } from '../context/AuthContext';
import { useParticipant } from '../context/ParticipantContext';
import { setDashPreferences, fetchDashPreferences, addParticipant } from '../functions/snapi';
import { set } from 'date-fns';

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PlaygroundSpeedDial() {
  const [direction, setDirection] =
    React.useState<SpeedDialProps['direction']>('up');
  const [hidden, setHidden] = React.useState(false);
  const [isPModalOpen, setIsPModalOpen] = useState(false);
  const [participantID, setParticipantID] = useState('');

  const { toggleDarkMode, isDarkMode, setDarkMode } = useTheme();
	const { 
    accessToken, setAccessToken, setUserSettingsState,
    selectedColumns, setSelectedColumns,
    showAnnotations, setShowAnnotations, userSettings,
    selectedStudy, setSelectedStudy
  } = useAuth(); // Use the context
  const { 
    selectedParticipant, setSelectedParticipant,
    selectedDate, setSelectedDate 
  } = useParticipant();
	const history = useHistory();

  const handleLogout = () => {
		setAccessToken(null); // Clear the access token from context
    setUserSettingsState(null);
		history.push('/login'); // Redirect to the login page
		console.log('Logged out');
	};

  // Function to toggle print mode
  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    }, 100);
  };

  const handleSaveSettings = async () => {
    // Make data a dict with the selected columns and showAnnotations, as well as keys
    const data = {
      selectedColumns,
      showAnnotations,
      isDarkMode
    };
    setUserSettingsState(data);
    await setDashPreferences({ accessToken, data });
  };

  const handleLoadSettings = async () => {
    const data = await fetchDashPreferences({ accessToken });
    if (data) {
      setSelectedColumns(data.selectedColumns);
      setShowAnnotations(data.showAnnotations);
      setDarkMode(data.isDarkMode);
    }
    setUserSettingsState(data);
  };

  const handlePOpen = () => {
    setIsPModalOpen(true);
  }

  const handlePClose = () => {
    setIsPModalOpen(false);
  };

  const handleRefresh = () => {
    let study = selectedStudy;
    setSelectedStudy(null);
    setTimeout(() => {
      setSelectedStudy(study);
    }, 100);

    if (selectedParticipant) {
      setSelectedParticipant(null);
      setTimeout(() => {
        setSelectedParticipant(selectedParticipant);
      }, 100);

      if (selectedDate) {
        setSelectedDate(null);
        setTimeout(() => {
          setSelectedDate(selectedDate);
        }, 100);
      }
    }
  }
  

  const actions = [
    { 
      icon: isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />,
      name: isDarkMode ? 'Light Mode' : 'Dark Mode',
      function: toggleDarkMode
    },
    ...(accessToken ? [
      // { 
      //   icon: <DashboardCustomizeIcon />,
      //   name: 'Save Layout',
      //   function: handleSaveSettings
      // },
      // { 
      //   icon: <DashboardIcon />,
      //   name: 'Load Layout',
      //   function: handleLoadSettings
      // },
      ...(selectedStudy ? [
        { 
          icon: <PersonAddIcon />,
          name: 'Add Participant',
          function: handlePOpen
        },
        { 
          icon: <CloudSyncIcon />,
          name: 'Refresh',
          function: handleRefresh
        }
      ] : []),
    ] : [])
  ];

  return (
      <Box sx={{ position: 'fixed', right: 20, bottom: 20, mt: 3, height: 320 }}>
        <StyledSpeedDial
          ariaLabel="Settings"
          hidden={hidden}
          icon={<SpeedDialIcon openIcon={<SettingsIcon />} />}
          direction={direction}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => action.function()}
            />
          ))}
        </StyledSpeedDial>
        
        <Dialog
          open={isPModalOpen}
          onClose={handlePClose}
          TransitionComponent={Transition}
          PaperProps={{
            component: 'form',
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              // If formJson starts with an '!', remove it, then check for ':' or ','.
              // If ':' is present, its a range of participants. If ',' is present, its a list of participants.
              
              if (formJson.participantID.startsWith('!')) {
                formJson.participantID = formJson.participantID.slice(1);
                if (formJson.participantID.includes(':')) {
                  const range = formJson.participantID.split(':');
                  const start = range[0];
                  const end = range[1];
                  const digitsMatch = start.match(/\d+/);
                  if (digitsMatch) {
                    const digits = digitsMatch[0];
                    const pIDStart = parseInt(digits);
                    const pIDEnd = parseInt(end.replace(/\D/g, ''));
                    const participantCount = pIDEnd - pIDStart + 1;
                    if (participantCount > 100) {
                      console.error('Error: Number of participants exceeds 100.');
                      return;
                    }
                    const prefix = start.slice(0, digitsMatch.index);
                    const suffix = start.slice(digitsMatch.index + digits.length);
                    for (let i = pIDStart; i <= pIDEnd; i++) {
                      const numStr = i.toString().padStart(digits.length, '0');
                      const pID = `${prefix}${numStr}${suffix}`;
                      addParticipant({ accessToken, participantID: pID, studyName: selectedStudy });
                    }
                  }
                }
                else if (formJson.participantID.includes(',')) {
                  const pIDs = formJson.participantID.split(',');
                  for (let i = 0; i < pIDs.length; i++) {
                    const pID = pIDs[i];
                    addParticipant({ accessToken, participantID: pID, studyName: selectedStudy });
                  }
                }
              } else {
                const pID = formJson.participantID;
                console.log(pID);
                addParticipant({ accessToken, participantID: pID, studyName: selectedStudy });
              }
              handlePClose();
            },
          }}
        >
          <DialogTitle>Adding participant</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add a participant to {selectedStudy}
            </DialogContentText>
            <TextField
              label="Participant ID"
              name='participantID'
              autoFocus
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </Dialog>
      </Box>
  );
}
